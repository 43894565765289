<template>
    <v-dialog max-width="410px" 
        v-model="dialog"
        @click:outside="close"
    >
        <div class="popup__content popup__content_email">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title" v-html="title"></h5>
            <v-btn class="bnt btn_primary" 
                color="primary" 
                depressed
                block
                @click="close"
            >
                {{$t('app.close')}}
            </v-btn>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        open() {
            this.dialog = true;
        },
        async close() {
            this.dialog = false;
            if (this.$route.name == 'ObjectsCreate' || this.$route.name == 'ObjectsEdit' || this.$route.name == 'ObjectsClone') {
                await this.$router.push({name: 'ObjectsSuccess', params: {lang: this.$route.params.lang}});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.popup__content_email {
    padding-top: 140px !important;
    background: #fff url(~@/assets/img/email.svg) center 50px/64px no-repeat;
}
</style>
